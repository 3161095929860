<template>
  <div>
    <v-card>
      <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
        <v-tab v-for="item in itemsList" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <hr style="margin: 2rem 0">
              <v-row>
                <v-col>
                  <span>مشاهده موجودی کیف پول مادر</span>
                </v-col>
                <v-col>
                  <router-link to="/accountingManage/ExchangeInventory">
                    <v-btn color="success">مشاهده</v-btn>
                  </router-link>
                </v-col>
                <v-col>
                  <v-btn color="success" @click="getExportTotalBalanceInInternalAccountingSystemAndBlockchain()">دریافت
                    گزارش xls
                  </v-btn>
                </v-col>
                <v-col></v-col>
              </v-row>
<!--              <hr style="margin: 2rem 0">-->
<!--              <v-row>-->
<!--                <v-col>مشاهده موجودی کیف پول کاربر</v-col>-->
<!--                <v-col>-->
<!--                  <input v-model="userNameSelected" class="Input">-->
<!--                </v-col>-->
<!--                <v-col>-->
<!--                  <v-btn @click="getAssetsInventory()" color="success">جست و جو</v-btn>-->
<!--                </v-col>-->
<!--                <v-col></v-col>-->
<!--              </v-row>-->
              <hr style="margin: 2rem 0">
              <v-row>
                <v-col>
                  <span>مشاهده موجودی حساب کارمزد ها</span>
                </v-col>
                <v-col>
                  <v-btn @click="getInventory()" color="success">مشاهده</v-btn>
                </v-col>
                <v-col>
                  <v-btn color="success">دریافت گزارش xls</v-btn>
                </v-col>
                <v-col>
                  <router-link :to="'/turnOverUser/sysfeecoll'">
                  <v-btn color="success">دریافت ریز حساب کار مزد ها</v-btn>
                  </router-link>
                </v-col>
                <v-col></v-col>
              </v-row>
              <hr style="margin: 2rem 0">
              <v-row>
                <v-col cols="6">
                  <span>مشاهده موجودی کیف پول حسابداری</span>
                </v-col>
                <v-col>
                  <v-btn @click="getInventoryFast()" color="success">مشاهده</v-btn>
                </v-col>
                <v-col></v-col>
              </v-row>
              <hr style="margin: 2rem 0">
              <v-row>
                <v-col cols="6">
                  <span>انتقال از حساب کارمزد به باشگاه مشتریان </span>
                </v-col>
                <v-col>
                  <v-btn @click="CustomerPopup = !CustomerPopup" color="success">مشاهده</v-btn>
                </v-col>
                <v-col></v-col>
              </v-row>
              <hr style="margin: 2rem 0">
              <h3>
                مدیریت بازار ها
              </h3>
              <v-row style="margin-top: 1rem">
                <v-col cols="8">نمایش سریع کل بازار ها</v-col>
                <v-col>
                  <router-link to="SpotOrdersQuickView">
                    <v-btn color="success">مشاهده</v-btn>
                  </router-link>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <span>انتخاب تالار</span>
                </v-col>
                <v-col cols="3">
                  <select id="accounting_rial_coin_options" class="InputSelect" v-model="pairSelected">
                    <option value="" selected disabled hidden>لطفا انتخاب کنید</option>
                    <option :value="pair.id" v-for="(pair, index) in pairs" :key="index">{{ pair.persianTitle }}</option>
                  </select>
                </v-col>
                <v-col>
                  <v-btn @click="GetBOpenOrdersQuickView()" color="success">مشاهده</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <span>
                    تعداد افراد موجود در صف فروش
                  </span>
                </v-col>
                <v-col cols="3">
                  <input class="disableInput" :value="OpenOrdersQuickView.sellOrdersCount">
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <span>بالاترین قیمت فروش</span>
                </v-col>
                <v-col cols="3">
                  <input class="disableInput" :value="OpenOrdersQuickView.sellOrdersMaxPrice">
                </v-col>
                <v-col cols="2">
                  <span>پایین ترین قیمت فروش</span>
                </v-col>
                <v-col cols="3">
                  <input class="disableInput" :value="OpenOrdersQuickView.sellOrdersMinPrice">
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <span>تعداد افراد موجود در صف خرید</span>
                </v-col>
                <v-col cols="3">
                  <input class="disableInput" :value="OpenOrdersQuickView.buyOrdersCount">
                </v-col>
                <v-col></v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <span>بالاترین قیمت خرید</span>
                </v-col>
                <v-col cols="3">
                  <input class="disableInput" :value="OpenOrdersQuickView.buyOrdersMaxPrice">
                </v-col>
                <v-col cols="2">
                  <span>پایین ترین قیمت خرید</span>
                </v-col>
                <v-col cols="3">
                  <input class="disableInput" :value="OpenOrdersQuickView.buyOrdersMinPrice">
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <hr style="margin: 2rem 0">
              <v-row>
                <v-col>
                  <span>وضعیت واریز و برداشت کاربران</span>
                </v-col>
                <v-col>
                  <v-btn min-width="150px" class="success" @click="getInternalPayment()">واریز ریالی</v-btn>
                </v-col>
                <v-col>
                  <v-btn min-width="150px" color="success" @click="WithdrawsGetList()">برداشت ریالی</v-btn>
                </v-col>
                <v-col>
                  <router-link to="Deposit">
                    <v-btn min-width="150px" color="success"> واریز رمز ارز</v-btn>
                  </router-link>
                </v-col>
                <v-col>
                  <v-btn min-width="150px" color="success" @click="WithdrawsExternalGetList()"> برداشت رمز ارز</v-btn>
                </v-col>
              </v-row>
              <hr style="margin: 2rem 0">
              <v-row>
                <v-col>
                  <span>اطلاعات آماری کاربران</span>
                </v-col>
                <v-col>
                  <v-btn min-width="150px" color="success" @click="getTickets()">گزارش تیکت ها</v-btn>
                </v-col>
                <v-col>
                  <v-btn min-width="150px" color="success" @click="getLoginHistory()">ورود و خروج کاربران</v-btn>
                </v-col>
                <v-col>
                </v-col>
                <v-col></v-col>
              </v-row>
              <hr style="margin: 2rem 0">
              <v-row>
                <v-col>
                  <span>اطلاعات حسابداری کاربران</span>
                </v-col>
                <v-col>
                  <v-btn min-width="150px" color="success" @click="getWallets('TOMAN')">موجودی ریالی</v-btn>
                </v-col>
                <v-col>
                  <v-btn min-width="150px" color="success" @click="getWallets('CRYPTO')">موجودی رمز ارز</v-btn>
                </v-col>
                <v-col></v-col>
                <v-col></v-col>
              </v-row>
              <hr style="margin: 2rem 0">
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <hr style="margin: 2rem 0">
              <v-row>
                <v-col>برداشت های تومانی</v-col>
                <v-col>
                  <v-btn color="success" @click="WithdrawGetList('pending')">در انتظار تایید</v-btn>
                </v-col>
                <v-col>
                  <v-btn color="success" @click="WithdrawGetList('Confirm')">تایید شده</v-btn>
                </v-col>
                <v-col>
                  <v-btn color="success" @click="WithdrawGetList('Paid')">پرداخت شده</v-btn>
                </v-col>
                <v-col>
                  <v-btn color="success" @click="WithdrawGetList('Rejected')">رد شده</v-btn>
                </v-col>
                <v-col>
                  <v-btn color="success" @click="WithdrawGetList('Cancel')">لغو شده توسط کاربر</v-btn>
                </v-col>
              </v-row>
              <hr style="margin: 2rem 0">
              <v-row>
                <v-col>برداشت های رمز ارز</v-col>
                <v-col>
                  <v-btn @click="WithdrawExternalGetList('pending')" color="success">در انتظار تایید</v-btn>
                </v-col>
                <v-col>
                  <v-btn @click="WithdrawExternalGetList('Confirm')" color="success">تایید شده</v-btn>
                </v-col>
                <v-col>
                  <v-btn @click="WithdrawExternalGetList('Paid')" color="success"> پرداخت شده</v-btn>
                </v-col>
                <v-col>
                  <v-btn @click="WithdrawExternalGetList('Rejected')" color="success">رد شده</v-btn>
                </v-col>
                <v-col>
                  <v-btn @click="WithdrawExternalGetList('Cancel')" color="success">لغو شده توسط کاربر</v-btn>
                </v-col>
              </v-row>
              <hr style="margin: 2rem 0">
              <v-row>
                <v-col>معامله سریع</v-col>
                <v-col>
                  <v-btn @click="WithdrawFastGetList('pending')" color="success">در انتظار تایید</v-btn>
                </v-col>
                <v-col>
                  <v-btn @click="WithdrawFastGetList('Confirm')" color="success">تایید شده</v-btn>
                </v-col>
                <v-col>
                  <v-btn @click="WithdrawFastGetList('Paid')" color="success">پرداخت شده</v-btn>
                </v-col>
                <v-col>
                  <v-btn @click="WithdrawFastGetList('Rejected')" color="success">رد شده</v-btn>
                </v-col>
                <v-col>
                  <v-btn @click="WithdrawFastGetList('Cancel')" color="success">لغو شده توسط کاربر</v-btn>
                </v-col>
              </v-row>
              <hr style="margin: 2rem 0">
            </v-card-text>
          </v-card>
          <v-card flat id="sheet" style="margin-top: 2rem;" :class="!visible ? 'visible' : ''">
            <v-card flat>
              <v-card-title>
                {{ title }}
                <v-spacer></v-spacer>
                <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
              </v-card-title>
              <v-data-table :headers="header" :items="items" :search="search">
                <template v-slot:item.operationsToman="{ item }" v-if="Toman">
                  <v-row>
                    <v-btn v-if="item.lastTransactionConfirmationType === 'Pending'" color="success" @click="acceptWithdraw(item.id)" style="min-width: 42px !important;width: 42px">تایید
                    </v-btn>
                    <v-btn v-if="item.lastTransactionConfirmationType === 'Pending'" color="error" @click="showRejectModal(item.id)" style="min-width: 42px !important;width: 42px">رد
                    </v-btn>
                    <v-btn v-if="item.lastTransactionConfirmationType === 'Accepted'" color="success" @click="showAcceptModal(item.id)" style="min-width: 42px !important;width: 42px">پرداخت
                    </v-btn>
                  </v-row>
                </template>
                <template v-slot:item.operationsExternal="{ item }" v-if="external">
                  <v-btn color="success" @click="acceptExternalWithdraw(item.id)" v-if="item.lastTransactionConfirmationType === 'Pending'" style="min-width: 50px!important;margin-left: 2px!important;">تایید
                  </v-btn>
                  <v-btn v-if="item.lastTransactionConfirmationType === 'Pending'" color="error" @click="showExternalRejectModal(item.id)" style="min-width: 50px!important;">رد
                  </v-btn>
                  <v-btn v-if="item.lastTransactionConfirmationType === 'Accepted'" color="success" @click="payExternalWithdraw(item.id)" style="min-width: 50px!important;margin-left: 2px!important;">پرداخت آفلاین
                  </v-btn>
                  <v-btn v-if="item.lastTransactionConfirmationType === 'Accepted'" color="success" @click="showExternalAcceptModal(item)" style="min-width: 50px!important;margin-left: 2px!important;">پرداخت از کیف پول
                  </v-btn>

                </template>
                <template v-slot:item.operations="{ item }" v-if="Toman === false && external === false">
                  <v-btn v-if="item.lastTransactionConfirmationType === 'Pending'" color="success" @click="acceptFastWithdraw(item.id)" style="min-width: 50px!important;margin-left: 1px">تایید
                  </v-btn>
                  <v-btn v-if="item.lastTransactionConfirmationType === 'Pending'" color="error" @click="showFastRejectModal(item.id)" style="min-width: 50px !important;">رد
                  </v-btn>
                  <v-btn v-if="item.lastTransactionConfirmationType === 'Accepted'" color="success" @click="showFastAcceptModal(item.id)" style="min-width: 50px !important;">پرداخت
                  </v-btn>
                </template>
                <template v-slot:item.orderType="{ item }">
                  {{ item.orderType === 'Buy' ? 'خرید' : 'فروش' }}
                </template>
                <template v-slot:item.createdAtUtc="{ item }">
                  {{ getPersianDate(item.createdAtUtc) }}
                </template>
                <template v-slot:item.withdrawDateTimeAtUtc="{ item }">
                  {{ getPersianDateOnly(item.withdrawDateTimeAtUtc) }}
                </template>
              </v-data-table>
            </v-card>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <Popup width="900px" :show.sync="CustomerPopup">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          انتقال به حساب باشگاه مشتریان
        </v-toolbar>
        <v-card-text class="rtl">
          <v-row style="margin-top: 20px">
            <v-col cols="5">
              <span>انتخاب رمز ارز</span>
            </v-col>
            <v-col cols="3">
              <select id="accounting_rial_coin_options" class="InputSelect" v-model="assetSelectedCustomer">
                <option value="" selected disabled hidden>لطفا انتخاب کنید</option>
                <option :value="asset.id" v-for="(asset, index) in assets" :key="index">{{ asset.persianTitle }}</option>
              </select>
            </v-col>
          </v-row>
          <div>
            <div class="modal-content form-group" style="width: auto">
              <v-row>
                <div style="padding: 4%">
                  <p style="font-size: 15px">مقدار را وارد کنید</p>
                </div>
                <div class="row" style="margin-top:20px">
                  <v-text-field outlined v-model="value" label="مقدار   : "></v-text-field>
                </div>
              </v-row>

              <div style="margin-right: 40%;margin-top: 10px">
                <v-btn color="success" @click="TransferCollectedFeeToCustomerClubAccount()">تایید </v-btn>
              </div>

              <hr style="border-top-color: white">
            </div>
          </div>
        </v-card-text>
      </template>
    </Popup>
    <Popup width="600px" :show.sync="rejectShow">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          رد درخواست
        </v-toolbar>
        <v-card-text class="rtl">

          <div>
            <div class="modal-content form-group" style="width: auto">
              <div style="padding: 4%">
                <p style="font-size: 15px">لطفا علت رد درخواست را وارد کنید</p>
              </div>
              <div class="row">
              </div>

              <div class="row" style="margin-top:20px">
                <v-text-field outlined v-model="comment" label="علت رد درخواست : "></v-text-field>
              </div>

              <div style="margin-right: 40%;margin-top: 10px">
                <v-btn color="success" @click="rejectWithdraw(rejectId)">رد درخواست</v-btn>
              </div>

              <hr style="border-top-color: white">
            </div>
          </div>
        </v-card-text>
      </template>
    </Popup>
    <Popup width="600px" :show.sync="acceptShow">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          تایید درخواست
        </v-toolbar>
        <v-card-text class="rtl">
          <div>
            <div class="modal-content form-group" style="width: auto">
              <div style="padding: 4%">
                <p style="font-size: 15px">لطفااطلاعات زیر را با دقت وارد کنید</p>
              </div>
              <div class="row">
              </div>

              <div class="row" style="margin-top:20px">
                <v-text-field v-model="trackingNumber" outlined label="شماره پیگیری : "></v-text-field>
              </div>

              <div class="row" style="margin-top:20px">
                <v-text-field v-model="comment" outlined label="توضیحات : "></v-text-field>
              </div>

              <div style="margin-right: 40%;margin-top: 10px">
                <v-btn color="success" @click="payWithdraw(acceptId)">تایید درخواست</v-btn>
              </div>
              <hr style="border-top-color: white">
              <div v-if="err">
                <div style="display: flex">
                  <div>completed : </div>
                  <div>{{this.errors.completed}}</div>
                </div>
                <div style="display: flex">
                  <div>httpStatusCode : </div>
                  <div>{{this.errors.httpStatusCode}}</div>
                </div>
                <div style="display: flex">
                  <div>id : </div>
                  <div>{{this.errors.id}}</div>
                </div>
                <div style="display: flex">
                  <div>txId : </div>
                  <div>{{this.errors.txId}}</div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </template>
    </Popup>

    <Popup width="600px" :show.sync="externalRejectShow">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          رد درخواست
        </v-toolbar>
        <v-card-text class="rtl">
          <div>
            <div class="modal-content form-group" style="width: auto">
              <div style="padding: 4%">
                <p style="font-size: 15px">لطفا علت رد درخواست را وارد کنید</p>
              </div>
              <div class="row">
              </div>

              <div class="row" style="margin-top:20px">
                <v-text-field v-model="comment" outlined label="علت رد درخواست :"></v-text-field>
              </div>

              <div style="margin-right: 40%;margin-top: 10px">
                <v-btn color="success" @click="rejectExternalWithdraw()">رد درخواست</v-btn>
              </div>
            </div>
          </div>
        </v-card-text>
      </template>
    </Popup>
    <Popup width="600px" v-if="externalAcceptShow" :show.sync="externalAcceptShow">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          تایید درخواست
        </v-toolbar>
        <v-card-text class="rtl">
          <div>
            <div class="modal-content form-group" style="width: auto">
              <div style="padding: 4%">
                <p style="font-size: 15px">لطفااطلاعات زیر را با دقت وارد کنید</p>
              </div>
              <div class="row">
              </div>
              <div style="display: flex;justify-content: space-between;">
                <div style="display: flex;">
                  <div>شناسه درخواست :  </div>
                  <div>{{this.item.id }}</div>
                </div>
                <div style="display: flex">
                  <div>شناسه کاربر : </div>
                  <div>{{ this.item.user.username }}</div>
                </div>
              </div>
              <div style="display: flex;justify-content: space-between;">
                <div style="display: flex">
                  <div>نام و نام خانوادگی کاربر :</div>
                  <div>{{ this.item.user.fullName }}</div>
                </div>
                  <div style="display: flex">
                  <div>DestinationTag :</div>
                  <div>{{ item.childWalletExtraFieldValue }}</div>
                </div>
              </div>
              <div style="display: flex;justify-content: space-between;">
                <div style="display: flex">
                  <div>رمز ارز : </div>
                  <div>{{ this.item.asset.symbol }}</div>
                </div>
                <div style="display: flex">
                  <div>شبکه : </div>
                  <div>{{ this.item.externalDestinationNetwork }}</div>
                </div>
              </div>
              <div style="display: flex;justify-content: space-between;">
                <div style="display: flex">
                  <div>مقدار : </div>
                  <div>{{ this.item.volume }}</div>
                </div>
                <div style="display: flex">
                  <div>کارمزد انتقال : </div>
                  <div>{{ this.item.networkFee }}</div>
                </div>
              </div>
              <div>
                <div >
                  <div>اکانت تیتوم کاربر :</div>
                  <div style="text-align: center">
                    <span class="borderSpan">
                      {{ this.item.tatumAccountId }}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>آدرس مقصد : </div>
                  <div style="text-align: center">
                    <span class="borderSpan">
                      {{ this.item.externalDestinationWalletAddress }}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>آدرس مبدا xpub : </div>
                </div>
                <div style="text-align: center">
                  <span class="borderSpan" v-if="xPub">
                    {{ xPub }}
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <div>امضاء تراکنش signatureId :</div>
                </div>
                <div  style="text-align: center">
                  <span class="borderSpan" v-if="signatureId">
                    {{ signatureId }}
                  </span>
                </div>
              </div>
              <div class="row" style="margin-top:20px">
                <v-text-field v-model="signatureId" outlined label=" امضاء تراکنش :"></v-text-field>
              </div>
              <div class="row" style="margin-top:20px">
                <v-text-field v-model="xPub" outlined label=" آدرس مبدا :"></v-text-field>
              </div>
              <div class="row" style="margin-top:20px">
                <v-text-field v-model="paymentTracingNumber" outlined label=" شماره پیگیری :"></v-text-field>
              </div>
              <div class="row" style="margin-top:20px">
                <v-text-field v-model="externalWithdrawId" outlined label=" externalWithdrawId :"></v-text-field>
              </div>
              <div class="row" style="margin-top:20px">
                <v-text-field v-model="gasPrice" outlined label=" gasPrice :"></v-text-field>
              </div>
              <div class="row" style="margin-top:20px">
                <v-text-field v-model="gasLimit" outlined label=" gasLimit :"></v-text-field>
              </div>
              <div class="row" style="margin-top:20px">
                <v-text-field v-model="networkFee" outlined label=" networkFee :"></v-text-field>
              </div>
              <div class="row" style="margin-top:20px">
                <v-text-field v-model="account" outlined label=" account :"></v-text-field>
              </div>
              <div class="row" style="margin-top:20px">
                <v-text-field v-model="fromAccount" outlined label=" fromAccount :"></v-text-field>
              </div>
              <div class="row" style="margin-top:20px">
                <v-text-field v-model="fee" outlined label=" fee :"></v-text-field>
              </div>

              <div style="margin-right: 40%;margin-top: 10px">
                <v-btn color="success" @click="payFromMainWallet()">تایید درخواست</v-btn>
              </div>

              <hr style="border-top-color: white">
            </div>
          </div>
        </v-card-text>
      </template>
    </Popup>

    <Popup width="600px" :show.sync="acceptFastShow">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          تایید درخواست
        </v-toolbar>
        <v-card-text class="rtl">
          <slot name="body"></slot>
          <div>
            <div class="modal-content form-group" style="width: auto">
              <div style="padding: 4%">
                <p style="font-size: 15px">لطفااطلاعات زیر را با دقت وارد کنید</p>
              </div>
              <div class="row">
              </div>

              <v-text-field outlined v-model="paymentTracingNumber" label="شماره پیگیری :"></v-text-field>
              <v-text-field outlined v-model="comment" label="توضیحات :"></v-text-field>

              <div style="margin-right: 40%;margin-top: 10px">
                <v-btn color="success" @click="PaidWithdraw(acceptId)">تایید درخواست</v-btn>
              </div>

              <hr style="border-top-color: white">
            </div>
          </div>
        </v-card-text>
      </template>

    </Popup>
    <Popup width="600px" :show.sync="rejectFastShow">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          رد درخواست
        </v-toolbar>
        <v-card-text class="rtl">
          <slot name="body"></slot>
          <div>
            <div class="modal-content form-group" style="width: auto">
              <div style="padding: 4%">
                <p style="font-size: 15px">لطفا علت رد درخواست را وارد کنید</p>
              </div>
              <div class="row">
              </div>
              <v-text-field outlined v-model="comment" label="علت رد درخواست  :"></v-text-field>

              <div style="margin-right: 40%;margin-top: 10px">
                <v-btn color="error" @click="rejectFastWithdraw(rejectId)">رد درخواست</v-btn>
              </div>

              <hr style="border-top-color: white">
            </div>
          </div>
        </v-card-text>
      </template>

    </Popup>

    <Popup :show.sync="showUserInformation">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">موجودی کاربر
        </v-toolbar>
        <v-card-text class="rtl">
          <slot name="body"></slot>
          <v-row v-if="assetsInventory != ''">
            <v-col>
              <v-list-item>نام : {{ assetsInventory[0].user.firstName }}</v-list-item>
            </v-col>
            <v-col>
              <v-list-item> نام خوانوادگی : {{ assetsInventory[0].user.lastName }}</v-list-item>
            </v-col>
            <v-col>
              <v-list-item> نام کاربری : {{ assetsInventory[0].user.username }}</v-list-item>
            </v-col>
          </v-row>
          <v-card>
            <v-card-title>
              موجودی
              <v-spacer></v-spacer>
              <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" outlined hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="headerInventory" :items="assetsInventory" :search="search">
            </v-data-table>
          </v-card>
        </v-card-text>
      </template>
    </Popup>
    <Popup :show.sync="showAssetInventory">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">موجودی صرافی
        </v-toolbar>
        <v-card-text class="rtl">
          <slot name="body"></slot>
          <div>
            <div class="modal-content form-group" style="width: auto">
              <div style="padding: 4%">
                <p style="font-size: 15px">جزعیات استعلام</p>
              </div>
              <div class="col-12" style="height: 40px;background-color: #252432;color: white; transition: 0.3s;direction: ltr;font-size: 13px;padding-top: 9px">
                <div class="row">
                  <div class="col-2">تاریخ</div>
                  <div class="col-3">موجودی بلاک چین</div>
                  <div class="col-3">موجودی داخلی</div>
                  <div class="col-3">نماد</div>
                </div>
              </div>
              <div class="col-12" style="height: 300px;overflow: auto;background-color: #252432;color: white; transition: 0.3s;direction: ltr;font-size: 13px;margin-top: 1%">
                <div class="row enterReport" style="height: 40px;padding-top: 5px;padding-bottom: 8px;margin-bottom: 10px">
                  <div class="col-2" v-if="this.lastBlockchainUpdateAtUtc != ''">
                    {{
                        this.lastBlockchainUpdateAtUtc != null || '' ? getPersianDate(this.lastBlockchainUpdateAtUtc) : 'تاریخ در دسترس نیست'
                    }}
                  </div>
                  <div class="col-3">{{ this.totalBalanceInBlockchain }}</div>
                  <div class="col-3">{{ this.totalBalanceInInternalAccountingSystem }}</div>
                  <div class="col-3">{{ this.symbol }}</div>
                </div>
              </div>
              <hr style="border-top-color: white">
            </div>
          </div>
        </v-card-text>
      </template>
    </Popup>

    <Popup :show.sync="rialShow">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          واریزی تومانی کاربران
        </v-toolbar>
        <v-card-text class="rtl">
          <slot name="body"></slot>
          <v-card>
            <v-card-title>
              واریزی تومانی کاربران
              <v-spacer></v-spacer>
              <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="headerToman" :items="internalPaymentList" :search="search">
              <template v-slot:item.dateTime="{ item }">
                {{ getPersianDate(item.dateTime) }}
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </template>

    </Popup>
    <Popup :show.sync="userRialPaymentShow">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          درخواست برداشت تومانی کاربران
        </v-toolbar>
        <v-card-text class="rtl">
          <slot name="body"></slot>
          <v-card>
            <v-card-title>
              لیست درخواست ها
              <v-spacer></v-spacer>
              <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="headerWithdraw" :items="withdrawList" :search="search">
              <template v-slot:item.status="{ item }">
                {{ item.lastTransactionConfirmationType == 'AcceptedAndPaid' ? 'تایید و پرداخت' : (item.lastTransactionConfirmationType == 'Rejected' ? 'رد شده' : (item.lastTransactionConfirmationType == 'Pending' ? 'در انتظار تایید' : 'کنسل شده توسط کاربر')) }}
              </template>
              <template v-slot:item.createdAtUtc="{ item }">
                {{ getPersianDate(item.createdAtUtc) }}
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </template>
    </Popup>
    <Popup :show.sync="withdrawCryptoShow">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          درخواست برداشت رمز ارز کاربران
        </v-toolbar>
        <v-card-text class="rtl">
          <slot name="body"></slot>
          <v-card>
            <v-card-title class="text-center justify-center py-6">
              <h1 class="font-weight-bold text-h5 basil--text">
                برداشت های رمز ارز
              </h1>
            </v-card-title>
            <v-tabs v-model="tabbox" background-color="transparent" color="basil" grow>
              <v-tab v-for="item in items" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabbox">
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-card>
                      <v-card-title>
                        در انتظار تایید
                        <v-spacer></v-spacer>
                        <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table :headers="headerWithdrawlExternalPending" :items="withdrawExternalList.filter((item) => item.lastTransactionConfirmationType === 'Pending')" :search="search">
                        <template v-slot:item.createdAtUtc="{ item }">
                          {{ getPersianDate(item.createdAtUtc) }}
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-card>
                      <v-card-title>
                        تایید شده
                        <v-spacer></v-spacer>
                        <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table :headers="headerWithdrawlExternalConfirm" :items="withdrawExternalList.filter((item) => item.lastTransactionConfirmationType === 'AcceptedAndPaid')" :search="search">
                        <template v-slot:item.createdAtUtc="{ item }">
                          {{ getPersianDate(item.createdAtUtc) }}
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-card>
                      <v-card-title>
                        درخواست های رد شده
                        <v-spacer></v-spacer>
                        <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table :headers="headerWithdrawlExternalRejected" :items="withdrawExternalList.filter((item) => item.lastTransactionConfirmationType === 'Rejected')" :search="search">
                        <template v-slot:item.createdAtUtc="{ item }">
                          {{ getPersianDate(item.createdAtUtc) }}
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-card>
                      <v-card-title>
                        کنسل شده توسط کاربر
                        <v-spacer></v-spacer>
                        <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table :headers="headerWithdrawlExternalCancel" :items="withdrawExternalList.filter((item) => item.lastTransactionConfirmationType === 'CanceledByUser')" :search="search">
                        <template v-slot:item.operations="{}">
                          کنسل شده توسط کاربر
                        </template>
                        <template v-slot:item.createdAtUtc="{ item }">
                          {{ getPersianDate(item.createdAtUtc) }}
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-card-text>
      </template>

    </Popup>
    <Popup :show.sync="ticketListShow">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          لیست تیکت ها
        </v-toolbar>
        <v-card-text class="rtl">
          <slot name="body"></slot>
          <v-card>
            <v-tabs v-model="tabTickets" background-color="transparent" color="basil" grow>
              <v-tab v-for="item in items" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabTickets">
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-card>
                      <v-card-title>
                        پیام های پشتیبانی
                        <v-spacer></v-spacer>
                        <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table :headers="headersTicket" :items="tickets.filter((item) => item.department.id === 2)" :search="search">
                        <template v-slot:item.operations="{ item }">
                          <v-row>
                            <router-link to="replyTicket">
                              <button class="btn-success" style="border-radius: 0.3em;padding-top: 1%;border: solid rgb(42,196,144) 1px" @click="$root.replyTicket = item.id"> جزییات
                              </button>
                            </router-link>
                            <button v-if="item.status != 'ClosedBySupportUser' && item.status != 'ClosedByEndUser'" class="btn-success" style="background-color: red!important;border: red;" @click="ticketClose(item.id)">بستن
                            </button>
                          </v-row>
                        </template>
                        <template v-slot:item.priority="{ item }">
                          {{ item.priority == 'Normal' ? 'متوسط' : (item.priority == 'Low') ? 'پایین' : 'بالا' }}
                        </template>
                        <template v-slot:item.status="{ item }">
                          {{ item.status == 'WaitingForSupportUserReply' ? 'در انتظار پاسخ ادمین' : (item.status == 'AnsweredBySupportUser' ? 'پاسخ داده شده توسط ادمین' : (item.status == 'ClosedByEndUser' ? 'بسته شده توسط کاربر' : (item.status == 'ClosedBySupportUser' ? 'بسته شده توسط پشتیبان' : 'بسته شده توسط سیستم'))) }}
                        </template>
                        <template v-slot:item.createdAtUtc="{ item }">
                          {{ getPersianDate(item.createdAtUtc) }}
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-card>
                      <v-card-title>
                        پیام های پشتیبانی
                        <v-spacer></v-spacer>
                        <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table :headers="headersTicket" :items="tickets.filter((item) => item.department.id === 3)" :search="search">
                        <template v-slot:item.operations="{ item }">
                          <v-row>
                            <router-link to="replyTicket">
                              <button class="btn-success" style="border-radius: 0.3em;padding-top: 1%;border: solid rgb(42,196,144) 1px" @click="$root.replyTicket = item.id"> جزییات
                              </button>
                            </router-link>
                            <button v-if="item.status != 'ClosedBySupportUser' && item.status != 'ClosedByEndUser'" class="btn-success" style="background-color: red!important;border: red;" @click="ticketClose(item.id)">بستن
                            </button>
                          </v-row>
                        </template>
                        <template v-slot:item.priority="{ item }">
                          {{ item.priority == 'Normal' ? 'متوسط' : (item.priority == 'Low') ? 'پایین' : 'بالا' }}
                        </template>
                        <template v-slot:item.status="{ item }">
                          {{ item.status == 'WaitingForSupportUserReply' ? 'در انتظار پاسخ ادمین' : (item.status == 'AnsweredBySupportUser' ? 'پاسخ داده شده توسط ادمین' : (item.status == 'ClosedByEndUser' ? 'بسته شده توسط کاربر' : (item.status == 'ClosedBySupportUser' ? 'بسته شده توسط پشتیبان' : 'بسته شده توسط سیستم'))) }}
                        </template>
                        <template v-slot:item.createdAtUtc="{ item }">
                          {{ getPersianDate(item.createdAtUtc) }}
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-card-text>
      </template>

    </Popup>
    <Popup :show.sync="entranceUsersShow">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          ورود و خروج کاربران
        </v-toolbar>
        <v-card-text class="rtl">
          <slot name="body"></slot>
          <div class="row" style="margin-top:20px">
            <div class="col-2" style="text-align: right;margin-top:10px">از تاریخ</div>
            <div class="col-3">
              <satrex-date-picker v-model="fromDateTime"></satrex-date-picker>
            </div>
            <div class="col-2" style="text-align: right;margin-top:10px">تا تاریخ</div>
            <div class="col-3">
              <satrex-date-picker v-model="toDateTime"></satrex-date-picker>
            </div>
            <div class="col-2">
              <button class="greenButton" @click="getLoginHistory()">جستجو</button>
            </div>
          </div>
          <hr style="margin: 2rem">
          <v-card style="margin-top: 10px">
            <v-card-title>
              ورود و خروج کاربران
              <v-spacer></v-spacer>
              <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="headerentrance" :items="userLoginHistory" :search="search">
              <template v-slot:item.createdAtUtc="{ item }">
                {{ getPersianDate(item.createdAtUtc) }}
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </template>

    </Popup>
    <Popup :show.sync="userRialInventoryShow">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          موجودی تومانی کاربران
        </v-toolbar>
        <v-card-text class="rtl">
          <slot name="body"></slot>
          <v-card>
            <v-card-title>
              موجودی تومانی
              <v-spacer></v-spacer>
              <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="headerTomanInventory" :items="userWallets.filter((item) => item.asset.symbol == 'TOMAN')" :search="search">
              <template v-slot:item.status="{ item }">
                <span @click="TradeDissAllow(item.id)" v-if="item.tradeAllowed === true" class="redButton">معاملات بسته شود </span>
                <span @click="TradeAllow(item.id)" v-if="item.tradeAllowed === false" class="greenButton">معاملات باز شود </span>
              </template>
              <template v-slot:item.createdAtUtc="{ item }">
                {{ getPersianDate(item.createdAtUtc) }}
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </template>

    </Popup>
    <Popup :show.sync="userCryptoInventoryShow">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">موجودی کاربر
        </v-toolbar>
        <v-card-text class="rtl">
          <slot name="body"></slot>
          <div class="row" style="margin: 10px">
            <v-list style="margin-top: 10px;margin-left: 10px">جستجو :</v-list>
            <v-text-field outlined v-model="userInventoryCrypto" label="شناسه کاربری"></v-text-field>
            <div class="col-3"></div>
            <div class="col-2">
              <button class="greenButton" @click="getWalletInventoryAsset()">جستجو</button>
            </div>
          </div>
          <v-row v-if="assetsInventory != ''">
            <v-col>
              <v-list-item>نام : {{ assetsInventory[0].user.firstName }}</v-list-item>
            </v-col>
            <v-col>
              <v-list-item> نام خوانوادگی : {{ assetsInventory[0].user.lastName }}</v-list-item>
            </v-col>
            <v-col>
              <v-list-item> نام کاربری : {{ assetsInventory[0].user.username }}</v-list-item>
            </v-col>
          </v-row>
          <v-card>
            <v-card-title>
              موجودی
              <v-spacer></v-spacer>
              <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" outlined hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="headerCryptoInventory" :items="assetsInventory.filter((item) => item.asset.symbol != 'TOMAN')" :search="search">
            </v-data-table>
          </v-card>
        </v-card-text>
      </template>

    </Popup>
  </div>
</template>

<script>
import axiosApi from "@/axios";
import getPersianDateTime from "@/plugins/getPersianDate";
import Vue from "vue";
import Popup from "@/components/Popup";
import axios from "axios";
import convertDate from "@/plugins/convertDate";
import { convertDateOnly } from "@/plugins/getPersianDate";
import moment from "jalali-moment";
import getEnv from "@/utils/getEnv";

export default {
  name: "FinancialSettings",
  components: {
    Popup
  },
  data() {
    return {
      selected: '',
      itemsList: ['حسابداری سامانه', 'رصدگر', 'درخواست های برداشت'],
      tab: null,
      tabbox: null,
      search: '',
      CustomerPopup: false,
      rejectShow: false,
      acceptShow: false,
      headerPending: [
        { text: 'نام و فامیل', value: 'user.fullName' },
        { text: 'شناسه', value: 'user.username' },
        { text: 'مقدار برداشت', value: 'price' },
        { text: 'تاریخ ایجاد', value: 'createdAtUtc' },
        { text: 'روز درخواست', value: 'withdrawDateTimeAtUtc' },
        { text: 'شماره شبا', value: 'bankAccount.iban' },
        { text: 'شماره کارت', value: 'bankAccount.cardNumber' },
        { text: 'عملیات', value: 'operationsToman' },
      ],
      headerConfirm: [
        { text: 'نام و فامیل', value: 'user.fullName' },
        { text: 'شناسه', value: 'user.username' },
        { text: 'مقدار برداشت', value: 'price' },
        { text: 'تاریخ ایجاد', value: 'createdAtUtc' },
        { text: 'روز درخواست', value: 'withdrawDateTimeAtUtc' },
        { text: 'شماره شبا', value: 'bankAccount.iban' },
        { text: 'شماره کارت', value: 'bankAccount.cardNumber' },
        { text: 'اقدام کننده', value: 'modifier.fullName' },
        { text: 'عملیات', value: 'operationsToman' },
      ],
      headerPay: [
        { text: 'نام و فامیل', value: 'user.fullName' },
        { text: 'شناسه', value: 'user.username' },
        { text: 'مقدار برداشت', value: 'price' },
        { text: 'تاریخ ایجاد', value: 'createdAtUtc' },
        { text: 'روز درخواست', value: 'withdrawDateTimeAtUtc' },
        { text: 'شماره شبا', value: 'bankAccount.iban' },
        { text: 'شماره کارت', value: 'bankAccount.cardNumber' },
        { text: 'اقدام کننده', value: 'modifier.fullName' },
        { text: 'شماره پیگیری', value: 'trackingNumber' },
      ],
      headerRejected: [
        { text: 'نام و فامیل', value: 'user.fullName' },
        { text: 'شناسه', value: 'user.username' },
        { text: 'مقدار برداشت', value: 'price' },
        { text: 'تاریخ ایجاد', value: 'createdAtUtc' },
        { text: 'روز درخواست', value: 'withdrawDateTimeAtUtc' },
        { text: 'شماره شبا', value: 'bankAccount.iban' },
        { text: 'شماره کارت', value: 'bankAccount.cardNumber' },
        { text: 'اقدام کننده', value: 'modifier.fullName' },
        { text: 'علت رد درخواست', value: 'comment' },
      ],
      headerCancel: [
        { text: 'نام و فامیل', value: 'user.fullName' },
        { text: 'شناسه', value: 'user.username' },
        { text: 'مقدار برداشت', value: 'price' },
        { text: 'روز درخواست', value: 'createdAtUtc' },
        { text: 'شماره شبا', value: 'bankAccount.iban' },
        { text: 'شماره کارت', value: 'bankAccount.cardNumber' },
      ],
      withdrawList: [],
      visible: false,
      title: '',
      assetSelectedCustomer: '',
      value: '',
      externalWithdrawId: '',
      gasPrice: '',
      gasLimit: '',
      networkFee: '',
      account: '',
      fromAccount: '',
      fee: '',
      header: [],
      err : false,
      errors : [],
      items: [],
      Toman: false,
      external: false,

      ExternalHeaderPending: [
        { text: 'نام و فامیل', value: 'user.fullName' },
        { text: 'شناسه', value: 'user.username' },
        { text: 'مقدار برداشت', value: 'volume' },
        { text: 'شبکه', value: 'externalDestinationNetwork' },
        { text: 'آدرس کیف پول مقصد', value: 'externalDestinationWalletAddress' },
        { text: 'روز درخواست', value: 'createdAtUtc' },
        { text: 'رمز ارز', value: 'asset.persianTitle' },
        { text: 'نماد', value: 'asset.symbol' },
        { text: 'عملیات', value: 'operationsExternal' },
      ],
      ExternalHeaderConfirm: [
        { text: 'نام و فامیل', value: 'user.fullName' },
        { text: 'شناسه', value: 'id' },
        { text: 'شناسه کاربر', value: 'user.username' },
        { text: 'مقدار برداشت', value: 'volume' },
        { text: 'رمز ارز', value: 'asset.persianTitle' },
        { text: 'روز درخواست', value: 'createdAtUtc' },
        { text: 'شبکه', value: 'externalDestinationNetwork' },
        // { text: 'آدرس کیف پول مقصد', value: 'externalDestinationWalletAddress' },
        // { text: 'نماد', value: 'asset.symbol' },
        { text: 'اقدانم کننده', value: 'modifier.fullName' },
        { text: 'عملیات', value: 'operationsExternal' },
      ],
      ExternalHeaderPaid: [
        { text: 'نام و فامیل', value: 'user.fullName' },
        { text: 'شناسه', value: 'user.username' },
        { text: 'مقدار برداشت', value: 'volume' },
        { text: 'شبکه', value: 'externalDestinationNetwork' },
        { text: 'آدرس کیف پول مقصد', value: 'externalDestinationWalletAddress' },
        { text: 'روز درخواست', value: 'createdAtUtc' },
        { text: 'رمز ارز', value: 'asset.persianTitle' },
        { text: 'نماد', value: 'asset.symbol' },
        { text: 'اقدانم کننده', value: 'modifier.fullName' },
        { text: 'شماره پیگیری', value: 'paymentTracingNumber' },
      ],
      ExternalHeaderRejected: [
        { text: 'نام و فامیل', value: 'user.fullName' },
        { text: 'شناسه', value: 'user.username' },
        { text: 'مقدار برداشت', value: 'volume' },
        { text: 'شبکه', value: 'externalDestinationNetwork' },
        { text: 'آدرس کیف پول مقصد', value: 'externalDestinationWalletAddress' },
        { text: 'روز درخواست', value: 'createdAtUtc' },
        { text: 'رمز ارز', value: 'asset.persianTitle' },
        { text: 'نماد', value: 'asset.symbol' },
        { text: 'اقدانم کننده', value: 'modifier.fullName' },
        { text: 'علت رد درخواست', value: 'comment' },
      ],
      ExternalHeaderCancel: [
        { text: 'نام و فامیل', value: 'user.fullName' },
        { text: 'شناسه', value: 'user.username' },
        { text: 'مقدار برداشت', value: 'volume' },
        { text: 'شبکه', value: 'externalDestinationNetwork' },
        { text: 'آدرس کیف پول مقصد', value: 'externalDestinationWalletAddress' },
        { text: 'روز درخواست', value: 'createdAtUtc' },
        { text: 'رمز ارز', value: 'asset.persianTitle' },
        { text: 'نماد', value: 'asset.symbol' },
      ],
      comment: '',
      paymentTracingNumber: '',
      externalRejectShow: false,
      externalAcceptShow: false,
      rejectId: '',
      acceptId: '',


      acceptFastShow: false,
      rejectFastShow: false,
      pendingHeader: [{
        text: 'نوع سفارش ',
        align: 'start',
        sortable: false,
        value: 'orderType'
      },
      { text: 'نام و نام خوانوادگی', value: 'user.fullname' },
      { text: 'شناسه', value: 'user.username' },
      { text: 'قیمت', value: 'price' },
      { text: 'مقدار برداشت', value: 'requestedVolume' },
      { text: 'تاریخ', value: 'createdAtUtc' },
      { text: 'رمز ارز', value: 'asset.persianTitle' },
      { text: 'نماد', value: 'asset.symbol' },
      { text: 'عملیات', value: 'operations' },],
      AcceptedHeader: [{
        text: 'نوع سفارش ',
        align: 'start',
        sortable: false,
        value: 'orderType'
      },
      { text: 'نام و نام خوانوادگی', value: 'user.fullname' },
      { text: 'شناسه', value: 'user.username' },
      { text: 'قیمت', value: 'price' },
      { text: 'مقدار برداشت', value: 'requestedVolume' },
      { text: 'تاریخ', value: 'createdAtUtc' },
      { text: 'رمز ارز', value: 'asset.persianTitle' },
      { text: 'نماد', value: 'asset.symbol' },
      { text: 'اقدام کننده', value: 'modifier.fullname' },
      { text: 'پرداخت', value: 'operations' }],
      PaidHeaders: [{
        text: 'نوع سفارش ',
        align: 'start',
        sortable: false,
        value: 'orderType'
      },
      { text: 'نام و نام خوانوادگی', value: 'user.fullname' },
      { text: 'شناسه', value: 'user.username' },
      { text: 'قیمت', value: 'price' },
      { text: 'مقدار برداشت', value: 'requestedVolume' },
      { text: 'تاریخ', value: 'createdAtUtc' },
      { text: 'رمز ارز', value: 'asset.persianTitle' },
      { text: 'نماد', value: 'asset.symbol' },
      { text: 'اقدام کننده', value: 'modifier.fullname' },
      { text: 'شماره پیگیری', value: 'paymentTracingNumber' }],
      RejectedHeader: [{
        text: 'نوع سفارش ',
        align: 'start',
        sortable: false,
        value: 'orderType'
      },
      { text: 'نام و نام خوانوادگی', value: 'user.fullname' },
      { text: 'شناسه', value: 'user.username' },
      { text: 'قیمت', value: 'price' },
      { text: 'مقدار برداشت', value: 'requestedVolume' },
      { text: 'تاریخ', value: 'createdAtUtc' },
      { text: 'رمز ارز', value: 'asset.persianTitle' },
      { text: 'نماد', value: 'asset.symbol' },
      { text: 'اقدام کننده', value: 'modifier.fullname' },
      { text: 'توضیحات', value: 'comment' }],
      CanceledByUserHeader: [{
        text: 'نوع سفارش ',
        align: 'start',
        sortable: false,
        value: 'orderType'
      },
      { text: 'نام و نام خوانوادگی', value: 'user.fullname' },
      { text: 'شناسه', value: 'user.username' },
      { text: 'قیمت', value: 'price' },
      { text: 'مقدار برداشت', value: 'requestedVolume' },
      { text: 'تاریخ', value: 'createdAtUtc' },
      { text: 'رمز ارز', value: 'asset.persianTitle' },
      { text: 'نماد', value: 'asset.symbol' }],

      showUserInformation: false,
      showAssetInventory: false,
      userNameSelected: '',
      trackingNumber: '',
      assetsInventory: [],
      headerInventory: [
        { text: 'نام', value: 'asset.persianTitle' },
        { text: 'نماد', value: 'asset.symbol' },
        { text: 'دارایی', value: 'totalFund' },
        { text: 'دارایی قابل معامله', value: 'totalTradable' },
      ],
      pairSelected: '',
      pairs: [],
      signatureId: '',
      xPub: '',
      item : [],
      assets: [],
      OpenOrdersQuickView: '',
      rialShow: false,
      internalPaymentList: [],
      headerToman: [
        {
          text: 'نام ',
          align: 'start',
          sortable: false,
          value: 'user.firstName',
        },
        { text: 'نام خوانوادگی', value: 'user.lastName' },
        { text: 'شناسه کاربری', value: 'user.username' },
        { text: 'ایمیل', value: 'user.email' },
        { text: 'مبلغ واریزی', value: 'volume' },
        { text: 'شناسه پرداخت داخلی', value: 'turnOverId' },
        { text: 'تاریخ', value: 'dateTime' },
      ],
      userRialPaymentShow: false,
      referralSharePercentageStatus: false,
      headerWithdraw: [
        { text: 'آی دی', value: 'id' },
        { text: 'نام و فامیل', value: 'user.fullName' },
        { text: 'شناسه', value: 'user.username' },
        { text: 'مقدار برداشت', value: 'price' },
        { text: 'روز درخواست', value: 'createdAtUtc' },
        { text: 'شماره شبا', value: 'bankAccount.iban' },
        { text: 'شماره کارت', value: 'bankAccount.cardNumber' },
        { text: 'وضعیت', value: 'status' },
      ],
      withdrawCryptoShow: false,
      withdrawExternalList: [],
      headerWithdrawlExternalPending: [
        { text: 'نام و فامیل', value: 'user.fullName' },
        { text: 'شناسه', value: 'user.username' },
        { text: 'مقدار برداشت', value: 'volume' },
        { text: 'آدرس کیف پول مقصد', value: 'externalDestinationWalletAddress' },
        { text: 'روز درخواست', value: 'createdAtUtc' },
        { text: 'رمز ارز', value: 'asset.persianTitle' },
        { text: 'نماد', value: 'asset.symbol' },
      ],
      headerWithdrawlExternalConfirm: [
        { text: 'نام و فامیل', value: 'user.fullName' },
        { text: 'شناسه', value: 'user.username' },
        { text: 'مقدار برداشت', value: 'volume' },
        { text: 'آدرس کیف پول مقصد', value: 'externalDestinationWalletAddress' },
        { text: 'روز درخواست', value: 'createdAtUtc' },
        { text: 'رمز ارز', value: 'asset.persianTitle' },
        { text: 'نماد', value: 'asset.symbol' },
        { text: 'اقدانم کننده', value: 'modifier.fullName' },
        { text: 'شماره پیگیری', value: 'paymentTracingNumber' },
      ],
      headerWithdrawlExternalRejected: [
        { text: 'نام و فامیل', value: 'user.fullName' },
        { text: 'شناسه', value: 'user.username' },
        { text: 'مقدار برداشت', value: 'volume' },
        { text: 'آدرس کیف پول مقصد', value: 'externalDestinationWalletAddress' },
        { text: 'روز درخواست', value: 'createdAtUtc' },
        { text: 'رمز ارز', value: 'asset.persianTitle' },
        { text: 'نماد', value: 'asset.symbol' },
        { text: 'اقدانم کننده', value: 'modifier.fullName' },
        { text: 'علت رد درخواست', value: 'comment' },
      ],
      headerWithdrawlExternalCancel: [
        { text: 'نام و فامیل', value: 'user.fullName' },
        { text: 'شناسه', value: 'user.username' },
        { text: 'مقدار برداشت', value: 'volume' },
        { text: 'آدرس کیف پول مقصد', value: 'externalDestinationWalletAddress' },
        { text: 'روز درخواست', value: 'createdAtUtc' },
        { text: 'رمز ارز', value: 'asset.persianTitle' },
        { text: 'نماد', value: 'asset.symbol' },
        { text: 'عملیات', value: 'operations' },
      ],
      ticketListShow: false,
      tickets: [],
      headersTicket: [
        {
          text: 'تیکت کد ',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'تلفن', value: 'starter.username' },
        { text: 'نام', value: 'starter.firstName' },
        { text: 'نام خوانوادگی', value: 'starter.lastName' },
        { text: 'موضوع', value: 'title' },
        { text: 'اولویت', value: 'priority' },
        { text: 'زمان ارسال', value: 'createdAtUtc' },
        { text: 'وضعیت', value: 'status' },
        { text: 'عملیات', value: 'operations' },
      ],
      tabTickets: null,
      userLoginHistory: [],
      entranceUsersShow: false,
      headerentrance: [
        { text: 'نام', value: 'user.firstName' },
        { text: 'فامیل', value: 'user.lastName' },
        { text: 'شناسه کاربری', value: 'user.userName' },
        { text: 'آی پی', value: 'ipAddress' },
        { text: 'مرورگر', value: 'browser.name' },
        { text: 'وضعیت', value: 'actionTitle' },
        { text: 'تاریخ', value: 'createdAtUtc' },
      ],
      userCryptoInventoryShow: false,
      userRialInventoryShow: false,
      userWallets: [],
      headerTomanInventory: [
        { text: 'نام', value: 'user.firstName' },
        { text: 'نام خوانوادگی', value: 'user.lastName' },
        { text: 'شناسه کاربری', value: 'user.username' },
        { text: 'دارایی', value: 'totalFund' },
        { text: 'دارایی قابل معامله', value: 'totalTradable' },
        { text: 'دارایی در بلاک چین', value: 'totalReceivedFromBlockChain' },
        { text: 'دارایی حسابداری داخلی', value: 'totalReceivedFromInternalMarket' },
        { text: 'وضعیت', value: 'status' },
      ],
      userInventoryCrypto: '',
      referralSharePercentage: '',
      referralSharePercentageUpdate: '',
      headerCryptoInventory: [
        { text: 'نام', value: 'asset.persianTitle' },
        { text: 'نماد', value: 'asset.symbol' },
        { text: 'دارایی', value: 'totalFund' },
        { text: 'دارایی قابل معامله', value: 'totalTradable' },
      ],
      fromDateTime: this.getDate(Date.now()),
      toDateTime: this.getDate(Date.now()),

    };
  },
  mounted() {
    this.getPairs();
    this.getAssets();
    this.getDefaultDefinition();
  },
  methods: {
    TransferCollectedFeeToCustomerClubAccount() {
      axiosApi().post('api/Manage/v1/CustomerClub/TransferCollectedFeeToCustomerClubAccount', {
        assetId: this.assetSelectedCustomer,
        volume: this.value
      }).then(({ data }) => {
        if (data['isSuccess']) {
          this.CustomerPopup = false;
          Vue.$toast.open({
            message: ' عملیات با موفقیت انجام شد',
            type: 'success',
            position: 'top-right'
          });
        }
      });
    },
    WithdrawGetList(type) {
      axiosApi().post('api/Manage/v1/InternalWithdraw/GetList')
        .then(({ data }) => {
          document.getElementById('sheet').scrollIntoView();
          if (type === 'pending') {
            this.items = data.data.filter((item) => item.lastTransactionConfirmationType === 'Pending');
            this.title = "در انتظار تایید برداشت تومانی";
            this.visible = true;
            this.Toman = true;
            this.external = false;
            this.selected = 'pending';
            this.header = this.headerPending;
          }
          if (type === 'Confirm') {
            this.items = data.data.filter((item) => item.lastTransactionConfirmationType === 'Accepted');
            this.title = "تایید شده ";
            this.visible = true;
            this.Toman = true;
            this.external = false;
            this.selected = 'Confirm';
            this.header = this.headerConfirm;
          }
          if (type === 'Paid') {
            this.items = data.data.filter((item) => item.lastTransactionConfirmationType === 'Paid');
            this.title = "پرداخت شده برداشت تومانی";
            this.visible = true;
            this.Toman = true;
            this.external = false;
            this.header = this.headerPay;
          }
          if (type === 'Rejected') {
            this.items = data.data.filter((item) => item.lastTransactionConfirmationType === 'Rejected');
            this.title = "رد شده برداشت تومانی";
            this.visible = true;
            this.Toman = true;
            this.external = false;
            this.header = this.headerRejected;
          }
          if (type === 'Cancel') {
            this.items = data.data.filter((item) => item.lastTransactionConfirmationType === 'CanceledByUser');
            this.title = "کنسل شده توسط کاربر برداشت تومانی";
            this.visible = true;
            this.Toman = true;
            this.external = false;
            this.header = this.headerCancel;
          }
        });
    },
    getPersianDate(date) {
      return getPersianDateTime(date);
    },
    getPersianDateOnly(date) {
      return convertDateOnly(date);
    },
    showRejectModal(id) {
      this.rejectId = id;
      this.rejectShow = true;
    },
    showAcceptModal(id) {
      this.acceptId = id;
      this.acceptShow = true;
    },
    acceptWithdraw(acceptId) {
      axiosApi().post('api/Manage/v1/InternalWithdraw/Accept', {
        internalWithdrawRequestId: acceptId,
        trackingNumber: '-',
        comment: '-'
      })
        .then(({ data }) => {
          if (data['isSuccess']) {
            this.comment = '';
            this.paymentTracingNumber = '';
            this.WithdrawGetList(this.selected);
            Vue.$toast.open({
              message: 'تایید درخواست با موفقیت انجام شد',
              type: 'success',
              position: 'top-right'
            });
          } else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    },
    payWithdraw(acceptId) {
      axiosApi().post('api/Manage/v1/InternalWithdraw/Pay', {
        internalWithdrawRequestId: acceptId,
        trackingNumber: this.trackingNumber,
        comment: this.comment
      })
        .then(({ data }) => {
          this.errors = data.data
          this.err = true
          if (data.data.completed === false)
          {
            Vue.$toast.open({
              message : 'مقادیر ارسالی نامعتبر است.',
              type : 'error'
            })
          }
          if (data['isSuccess']) {
            this.comment = '';
            this.paymentTracingNumber = '';
            this.WithdrawGetList(this.selected);
            Vue.$toast.open({
              message: 'پرداخت درخواست با موفقیت انجام شد',
              type: 'success',
              position: 'top-right'
            });
          } else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    },
    rejectWithdraw(id) {
      axiosApi().post('api/Manage/v1/InternalWithdraw/Reject', {
        internalWithdrawId: id,
        comment: this.comment
      })
        .then(({ data }) => {
          if (data['isSuccess']) {
            this.comment = '';
            this.WithdrawGetList(this.selected);
            Vue.$toast.open({
              message: 'رد درخواست با موفقیت انجام شد',
              type: 'success',
              position: 'top-right'
            });
          } else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    },

    TradeAllow(id) {
      axiosApi().post('api/Manage/v1/Wallet/TradeAllow', {
        id: id
      })
        .then(({ data }) => {
          if (data['isSuccess']) {
            this.comment = '';
            this.getWallets('TOMAN');
            Vue.$toast.open({
              message: 'عملیات با موفقیت انجام شد',
              type: 'success',
              position: 'top-right'
            });
          } else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    },
    TradeDissAllow(id) {
      axiosApi().post('api/Manage/v1/Wallet/TradeDisallow', {
        id: id
      })
        .then(({ data }) => {
          if (data['isSuccess']) {
            this.comment = '';
            this.getWallets('TOMAN');
            Vue.$toast.open({
              message: 'عملیات با موفقیت انجام شد',
              type: 'success',
              position: 'top-right'
            });
          } else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    },

    WithdrawExternalGetList(type) {
      axiosApi().post('api/Manage/v1/ExternalWithdraw/GetList')
        .then(({ data }) => {
          this.withdrawExternalList = data.data;
          document.getElementById('sheet').scrollIntoView();
          if (type === 'pending') {
            this.items = data.data.filter((item) => item.lastTransactionConfirmationType === 'Pending');
            this.title = "در انتظار تایید برداشت رمز ارز";
            this.visible = true;
            this.Toman = false;
            this.selected = 'pending';
            this.external = true;
            this.header = this.ExternalHeaderPending;
          }
          if (type === 'Confirm') {
            this.items = data.data.filter((item) => item.lastTransactionConfirmationType === 'Accepted');
            this.title = "تایید شده";
            this.visible = true;
            this.Toman = false;
            this.selected = 'Confirm';
            this.external = true;
            this.header = this.ExternalHeaderConfirm;
          }
          if (type === 'Paid') {
            this.items = data.data.filter((item) => item.lastTransactionConfirmationType === 'Paid');
            this.title = "تایید شده و پرداخت شده برداشت رمز ارز";
            this.visible = true;
            this.Toman = false;
            this.external = true;
            this.header = this.ExternalHeaderPaid;
          }
          if (type === 'Rejected') {
            this.items = data.data.filter((item) => item.lastTransactionConfirmationType === 'Rejected');
            this.title = "رد شده برداشت رمز ارز";
            this.visible = true;
            this.Toman = false;
            this.external = true;
            this.header = this.ExternalHeaderRejected;
          }
          if (type === 'Cancel') {
            this.items = data.data.filter((item) => item.lastTransactionConfirmationType === 'CanceledByUser');
            this.title = "کنسل شده توسط کاربر برداشت رمز ارز";
            this.visible = true;
            this.Toman = false;
            this.external = true;
            this.header = this.ExternalHeaderCancel;
          }
        });
    },
    acceptExternalWithdraw(acceptId) {
      axiosApi().post('api/Manage/v1/ExternalWithdraw/Accept', {
        externalWithdrawId: acceptId,
        comment: "-",
        paymentTracingNumber: "-"
      })
        .then(({ data }) => {
          if (data['isSuccess']) {
            this.comment = '';
            this.paymentTracingNumber = '';
            this.WithdrawExternalGetList(this.selected);
            this.externalAcceptShow = false;
            Vue.$toast.open({
              message: 'تایید درخواست با موفقیت انجام شد',
              type: 'success',
              position: 'top-right'
            });
          } else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    },
    payExternalWithdraw(id) {
      axiosApi().post('api/Manage/v1/ExternalWithdraw/Pay', {
        externalWithdrawId: id,
        comment: this.comment
      })
        .then(({ data }) => {
          if (data['isSuccess']) {
            this.comment = '';
            this.paymentTracingNumber = '';
            this.WithdrawExternalGetList(this.selected);
            Vue.$toast.open({
              message: 'تایید درخواست با موفقیت انجام شد',
              type: 'success',
              position: 'top-right'
            });
          } else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    },
    payFromMainWallet() {
      axiosApi().post('api/Manage/v1/ExternalWithdraw/PayFromMainWallet', {
        externalWithdrawId: this.acceptId,
        comment: this.comment,
        paymentTracingNumber: this.paymentTracingNumber,
        signatureId : this.signatureId,
        xPub : this.xPub,
        gasPrice : this.gasPrice,
        gasLimit : this.gasLimit,
        networkFee : this.networkFee,
        account : this.account,
        fromAccount : this.fromAccount,
        fee : this.fee,
      })
        .then(({ data }) => {
          if (data['isSuccess']) {
            this.comment = '';
            this.paymentTracingNumber = '';
            this.WithdrawExternalGetList(this.selected);
            Vue.$toast.open({
              message: 'تایید درخواست با موفقیت انجام شد',
              type: 'success',
              position: 'top-right'
            });
          } else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    },
    rejectExternalWithdraw() {
      axiosApi().post('api/Manage/v1/ExternalWithdraw/Reject', {
        externalWithdrawRequestId: this.rejectId,
        comment: this.comment
      })
        .then(({ data }) => {
          if (data['isSuccess']) {
            this.comment = '';
            this.WithdrawExternalGetList(this.selected);
            this.externalRejectShow = false;
            Vue.$toast.open({
              message: 'رد درخواست با موفقیت انجام شد',
              type: 'success',
              position: 'top-right'
            });
          } else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    },
    showExternalRejectModal(id) {
      this.rejectId = id;
      this.externalRejectShow = true;
    },
    showExternalAcceptModal(item) {
      this.acceptId = item.id;
      this.item = item
      this.externalAcceptShow = true;
    },

    WithdrawFastGetList(type) {
      axiosApi().post('api/Manage/v1/FastExchange/GetList')
        .then(({ data }) => {
          this.withdrawFastList = data.data;
          document.getElementById('sheet').scrollIntoView();
          if (type === 'pending') {
            this.items = data.data.filter((item) => item.lastTransactionConfirmationType === 'Pending');
            this.title = "در انتظار تایید خرید فروش سریع";
            this.visible = true;
            this.selected = 'pending';
            this.Toman = false;
            this.external = false;
            this.header = this.pendingHeader;
          }
          if (type === 'Confirm') {
            this.items = data.data.filter((item) => item.lastTransactionConfirmationType === 'Accepted');
            this.title = "تایید شده";
            this.visible = true;
            this.Toman = false;
            this.selected = 'Confirm';
            this.external = false;
            this.header = this.AcceptedHeader;
          }
          if (type === 'Paid') {
            this.items = data.data.filter((item) => item.lastTransactionConfirmationType === 'Paid');
            this.title = " پرداخت شده خرید فروش سریع";
            this.visible = true;
            this.Toman = false;
            this.external = false;
            this.header = this.PaidHeaders;
          }
          if (type === 'Rejected') {
            this.items = data.data.filter((item) => item.lastTransactionConfirmationType === 'Rejected');
            this.title = "رد شده خرید فروش سریع";
            this.visible = true;
            this.Toman = false;
            this.external = false;
            this.header = this.RejectedHeader;
          }
          if (type === 'Cancel') {
            this.items = data.data.filter((item) => item.lastTransactionConfirmationType === 'CanceledByUser');
            this.title = "کنسل شده توسط کاربر خرید فروش سریع";
            this.visible = true;
            this.Toman = false;
            this.external = false;
            this.header = this.CanceledByUserHeader;
          }
        });
    },
    acceptFastWithdraw(acceptId) {
      axiosApi().post('api/Manage/v1/FastExchange/Accept', {
        fastExchangeRequestId: acceptId,
        comment: '-',
        paymentTracingNumber: '-'
      })
        .then(({ data }) => {
          if (data['isSuccess']) {
            this.comment = '';
            this.paymentTracingNumber = '';
            this.WithdrawFastGetList(this.selected);
            this.acceptFastShow = false;
            Vue.$toast.open({
              message: 'تایید درخواست با موفقیت انجام شد',
              type: 'success',
              position: 'top-right'
            });
          } else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    },
    PaidWithdraw(acceptId) {
      axiosApi().post('api/Manage/v1/FastExchange/Pay', {
        fastExchangeRequestId: acceptId,
        comment: this.comment,
        paymentTracingNumber: this.paymentTracingNumber
      })
        .then(({ data }) => {
          if (data['isSuccess']) {
            this.comment = '';
            this.paymentTracingNumber = '';
            this.WithdrawFastGetList(this.selected);
            this.acceptFastShow = false;
            Vue.$toast.open({
              message: 'تایید درخواست با موفقیت انجام شد',
              type: 'success',
              position: 'top-right'
            });
          } else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    },
    rejectFastWithdraw(id) {
      axiosApi().post('api/Manage/v1/FastExchange/Reject', {
        fastExchangeRequestId: id,
        comment: this.comment
      })
        .then(({ data }) => {
          if (data['isSuccess']) {
            this.comment = '';
            this.WithdrawFastGetList(this.selected);
            this.rejectFastShow = false;
            Vue.$toast.open({
              message: 'رد درخواست با موفقیت انجام شد',
              type: 'success',
              position: 'top-right'
            });
          } else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    },
    showFastRejectModal(id) {
      this.rejectId = id;
      this.rejectFastShow = true;
    },
    showFastAcceptModal(id) {
      this.acceptId = id;
      this.acceptFastShow = true;
    },
    convertTZ(date, tzString) {
      return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
    },

    getExportTotalBalanceInInternalAccountingSystemAndBlockchain() {
      axios({

        url: `https://managerapi.${getEnv()}.ir/api/Manage/v1/Report/ExportTotalBalanceInInternalAccountingSystemAndBlockchain`, //your url
        // url: 'https://managerapi.narwhal.ir/api/Manage/v1/Report/ExportTotalBalanceInInternalAccountingSystemAndBlockchain', //your url
        method: 'POST',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    getAssetsInventory() {
      if (this.userNameSelected === '') {
        Vue.$toast.open({
          message: 'لطفا شناسه کاربری را وارد کنید',
          type: 'error',
          position: 'top-right'
        });
        return;
      }
      this.showUserInformation = true;
      axiosApi().post('/api/Manage/v1/Wallet/GetList', {
        username: this.userNameSelected
      }).then(({ data }) => {
        this.assetsInventory = data.data;
      });
    },
    getInventory() {
      this.showUserInformation = true;
      axiosApi().post('/api/Manage/v1/Wallet/GetList', {
        username: 'SysFeeColl'
      }).then(({ data }) => {
        this.assetsInventory = data.data;
      });
    },
    getInventoryFast() {
      this.showUserInformation = true;
      axiosApi().post('/api/Manage/v1/Wallet/GetList', {
        username: 'SysFastExch'
      }).then(({ data }) => {
        this.assetsInventory = data.data;
      });
    },
    GetBOpenOrdersQuickView() {
      axiosApi().post('/api/Manage/v1/Board/GetBOpenOrdersQuickView', {
        "pairId": this.pairSelected
      })
        .then(({ data }) => {
          this.OpenOrdersQuickView = data.data;
        });

    },
    getPairs() {
      axiosApi().post('api/Manage/v1/Pair/GetList')
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.pairs = data.data;
        });
    },
    getAssets() {
      axiosApi().post('api/Manage/v1/Asset/GetList')
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.assets = data.data;
        });
    },


    getInternalPayment() {
      this.rialShow = true;
      axiosApi().post('api/Manage/v1/TurnOver/GetList', {
        turnOverType: 'InternalPayment'
      }).then(({ data }) => {
        if (data.isSuccess)
          this.internalPaymentList = data.data.reverse();
        this.visible = false;
      });
    },
    WithdrawsGetList() {
      this.userRialPaymentShow = true;
      axiosApi().post('api/Manage/v1/InternalWithdraw/GetList')
        .then(({ data }) => {
          this.withdrawList = data.data;
          this.visible = false;
        });
    },
    WithdrawsExternalGetList() {
      this.items = ['در انتظار تایید', 'تایید شده', 'رد شده', 'کنسل شده توسط کاربر '];
      this.withdrawCryptoShow = true;
      axiosApi().post('api/Manage/v1/ExternalWithdraw/GetList')
        .then(({ data }) => {
          this.withdrawExternalList = data.data;
          this.visible = false;
        });

    },
    getTickets() {
      this.items = [
        'پشتیبانی فنی', 'پشتیبانی مالی',
      ];
      this.ticketListShow = true;
      this.visible = false;
      axiosApi().post('/api/Manage/v1/Ticket/GetList', {}).then(({ data }) => {
        this.tickets = data.data;
      });
    },
    getTicketsFilterDate() {
      axiosApi().post('/api/Manage/v1/Ticket/GetList', {
        fromDateTime: convertDate(this.fromDateTime),
        toDateTime: convertDate(this.toDateTime)
      }).then(({ data }) => {
        this.tickets = data.data;
      });
    },
    getLoginHistory() {
      this.entranceUsersShow = true;
      this.visible = false;
      axiosApi().post('/api/Manage/v1/LoginHistory/GetList',
        {
          fromDateTime: convertDate(this.fromDateTime),
          toDateTime: convertDate(this.toDateTime)
        })
        .then(({ data }) => {
          this.userLoginHistory = data.data;
        });
    },
    getWallets(type) {
      this.visible = false;
      if (type === 'TOMAN')
        this.userRialInventoryShow = true;

      if (type == 'CRYPTO')
        this.userCryptoInventoryShow = true;

      axiosApi().post('api/Manage/v1/Wallet/GetList', {})
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.userWallets = data.data;
        });
    },
    getWalletInventoryAsset() {
      if (this.userInventoryCrypto === '') {
        Vue.$toast.open({
          message: 'لطفا شناسه کاربری را وارد کنید',
          type: 'error',
          position: 'top-right'
        });
        return;
      }
      axiosApi().post('/api/Manage/v1/Wallet/GetList', {
        username: this.userInventoryCrypto
      }).then(({ data }) => {
        this.assetsInventory = data.data;
      });
    },
    getDate(date) {
      return moment(date).locale('fa').format('jYYYY-jMM-jDD');
    },
    getDefaultDefinition() {
      axiosApi().post('api/Manage/v1/ReferralShare/GetDefaultDefinition')
        .then((data) => {
          this.referralSharePercentage = data.data.data.referralSharePercentage;
        });
    },
    UpdateDefaultDefinition() {
      axiosApi().post('api/Manage/v1/ReferralShare/UpdateDefaultDefinition', {
        referralSharePercentage: this.referralSharePercentageUpdate
      }).then(() => {
        Vue.$toast.open({
          message: 'مقدار پیشفرض با موفقیت تغییر یافت',
          type: 'success',
          position: 'top-right'
        });
        this.referralSharePercentageStatus = false;
        this.getDefaultDefinition();
      });
    }
  }
};
</script>

<style scoped>
.visible {
  visibility: hidden;
}
.borderSpan{
  border: 3px solid red;
  border-radius: 25px;
  padding: 0px 10px 0px 10px;
}
</style>
